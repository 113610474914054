import { SET_USER_ID, SET_USER_TYPE, SET_BATCH_ID, SET_BATCH_INFO_FOR_DASHBOARD, SET_UPLOADED_SHEETS, SET_BATCH_ROI, SET_BATCH_VALIDITY, SET_INTERPRETATION, SET_QUALITY_CHECK, SET_QUALITY_CHECK_ERROR, SET_SUMMARY, SET_USER_INFO, SET_DEMO, SET_AUTHENTICATED, SET_LOGIN_URL, SET_ALL_SAMPLE_INFO } from "../actions/types";

const initialState = {
  user_id: null,
  user_type: "lt",
  batch_id: null,
  quality_check_payload: {},
  quality_check_payload_error: {},
  quality_check_passed: null,
  initiated_batch: {},
  batch_roi: {},
  interpretation: {},
  batch_Validity_data: {},
  summary: {},
  user_info: {},
  batch_info_dashboard: {},
  all_sample_info: {},
  demo: false,
  is_authenticated: true,
  login_url: ''
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_ID:
      return Object.assign({}, state, {
        user_id: action.data
      });
    case SET_USER_TYPE:
    return Object.assign({}, state, {
      user_type: action.data
    });
    case SET_BATCH_ID:
      return Object.assign({}, state, {
        batch_id: action.data
      });
    case SET_QUALITY_CHECK:
    return Object.assign({}, state, {
      quality_check_passed: true,
      quality_check_payload: action.data,
      quality_check_payload_error: {}
    });
    case SET_QUALITY_CHECK_ERROR:
    return Object.assign({}, state, {
      quality_check_passed: false,
      quality_check_payload: {},
      quality_check_payload_error: action.data
    });
    case SET_UPLOADED_SHEETS:
      return Object.assign({}, state, {
        initiated_batch: action.data
      });
    case SET_BATCH_ROI:
      return Object.assign({}, state, {
        batch_roi: action.data
      });
    case SET_INTERPRETATION:
    return Object.assign({}, state, {
      interpretation: action.data
    });
    case SET_BATCH_VALIDITY:
    return Object.assign({}, state, {
      batch_Validity_data: action.data
    });

    case SET_SUMMARY:
    return Object.assign({}, state, {
      summary: action.data
    });

    case SET_USER_INFO:
    return Object.assign({}, initialState, {
      user_info: action.data,
      is_authenticated: state.is_authenticated,
      login_url: state.login_url,
      user_type: state.user_type
    });

    case SET_BATCH_INFO_FOR_DASHBOARD:
    return Object.assign({}, state, {
      batch_info_dashboard: action.data
    });

    case SET_ALL_SAMPLE_INFO: 
    return Object.assign({}, state, {
      all_sample_info: action.data
    });
    
    case SET_DEMO:
    return Object.assign({}, state, {
      demo: action.data
    });

    case SET_AUTHENTICATED:
    return Object.assign({}, state, {
      is_authenticated: action.data
    });

    case SET_LOGIN_URL:
      return Object.assign({}, state, {
        login_url: action.data
      });
    

    default:
      return state;
  }
};
