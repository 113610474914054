/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { createBatch, deleteSheet, getBatchRoi, getQualityCheck, getSheetsInBatch, setQualityCheck, setQualityCheckError, submitLabIdsForBatch, getInterpretation } from "../actions/global"
import {
  logBadImageQualityEvent,
  logPreviewSheetEvent,
  logSheetUploadedEvent
} from "../analytics/analyticsHelper";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import BreadCrumbList from '../components/breadCrumbList';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import DWT from '../components/dwtScan'
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import ErrorDialog from '../components/errorDialog'
import Grid from '@material-ui/core/Grid';
import globalColors from '../theme/colors';
import globalUseStyle from '../theme/customComponents';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ImageCarousel from '../components/carousel'
import LinearProgress from '@material-ui/core/LinearProgress';
import { PageTitle } from "../constants/app-contants";
import Paper from '@material-ui/core/Paper';
import PublishIcon from '@material-ui/icons/Publish';
// import ReportDialog from "../components/report"
import ScannerInstructionsDialog from '../components/scannerInstructions';
import { ScreenNames } from "../constants/CustomEnums";
import SelectLPATypeDialog from "../components/selectLPAType"
import { TipsSheetComponent } from "../components/tips/TipsSheetComponent";
import Typography from '@material-ui/core/Typography';
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { useLocation } from 'react-router-dom'

// import { useHistory } from 'react-router-dom';

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: globalColors.brandColor1,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 630,
  },
  batchImageContainer: {
    overflow: 'auto',
    paddingLeft: "30px",
    paddingRight: "30px"
  },
  container: {
    padding: 40,
  },
  nextStepButton: {
    justifyContent: "center",
    display: "flex",
    // paddingTop: "50px"
  },
  rightSideDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 150
  },
  c1: {
    paddingTop: 5,
    paddingBottom: 10,
    alignItems: "center"
  },
  heading: {
    display: "flex",
    justifyContent: "flex-start",
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText("#2B78C2"),
    backgroundColor: "#2B78C2",
    height: "48px",
    paddingLeft: "30px",
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: "#2B78C2",
      "@media (hover: none)": {
        backgroundColor: "#2B78C2"
      }
    }
  },
  reportButton: {
    color: "#1967D2",
    height: "13px",
    "&:hover": {
      backgroundColor: alpha("#1967D2", theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    }
  },
  nextButton: {
    margin: theme.spacing(2),
    color: theme.palette.getContrastText("#2B78C2"),
    backgroundColor: "#2B78C2",
    "&:hover": {
      backgroundColor: "#2B78C2",
      "@media (hover: none)": {
        backgroundColor: "#2B78C2"
      }
    }
  },
  dialogContent: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0.1px",
    alignItems: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

const Scanner = ({ setState }) => {
  // var userId = Number(location.pathname.slice(-1));
  const temp = useSelector(state => state.globalActionsReducer);
  const userId = useSelector(state => state.globalActionsReducer.user_id);
  const userType = useSelector(state => state.globalActionsReducer.user_type);
  const classes = useStyles();
  const globalClasses = globalUseStyle();

  const pageList = ['Upload']

  const [qcLoading, setQcLoading] = useState(false); // for the QC API call
  const [submitLoading, setSubmitLoading] = useState(false); // for the Submit API call
  const [deleteSheetId, setDeleteSheetId] = React.useState(-1);
  const [currentFile, setCurrentFile] = React.useState({});
  const initiatedBatch = useSelector(state => state.globalActionsReducer.initiated_batch);
  const [batchType, setBatchType] = React.useState("");
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [scanResponse, setScanResponse] = React.useState()
  const [reuploadSheetId, setReuploadSheetId] = React.useState(-1);
  const [scannerCount, setScannerCount] = React.useState(0)
  const [showScannerDialog, setShowScannerDialog] = React.useState(false)
  
  // const [reportDialog, setReportDialog] = React.useState(false);
  const [selectLPADialog, setSelectLPADialog] = React.useState(true);
  // const [uploadedImage, setUploadedImage] = React.useState("")
  const [erroDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorHeading, setErrorHeading] = React.useState("");
  const [errorContent, setErrorContent] = React.useState("");
  const [imagePreview, setImagePreview] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [uploadedFilesChangeTracker, setUploadedFilesChangeTracer] = React.useState(true);
  const [enterNikshayIdsButtonState, setEnterNikshayIdsButtonState] = React.useState(false);
  const dispatch = useDispatch();
  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
  const demo = useSelector(state => state.globalActionsReducer.demo)

  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };
  const qualityCheckPassed = useSelector(state => state.globalActionsReducer.quality_check_passed);
  const fileQualityPayload = useSelector(state => state.globalActionsReducer.quality_check_payload);
  const fileQualityPayloadError = useSelector(state => state.globalActionsReducer.quality_check_payload_error);
  useEffect(() => {
    if (fileQualityPayload?.sheet_quality === false) {
      // Log wrong image
      logBadImageQualityEvent()
    }
    if (fileQualityPayloadError?.msg_code === undefined) {
      var newFile = {
          sheet_id: fileQualityPayload?.sheet_id,
          sheet_type: fileQualityPayload?.sheet_type,
          sheet_quality: fileQualityPayload?.sheet_quality,
          error_message: fileQualityPayload?.error_message,
          transformed_image_location: fileQualityPayload?.transformed_image_location,
          original_image_location: fileQualityPayload?.original_image_location,
          thumbnail_image_location: fileQualityPayload?.thumbnail_image_location
        }
      uploadedFiles.push(newFile)
      setUploadedFiles(uploadedFiles);
      setCurrentFile({})
    }
  }, [dispatch, fileQualityPayload])

  const [selectLPATypeState, setSelectLPATypeState] = useState(-1);
  useEffect(() => {
    if (selectLPATypeState !== -1) {
    dispatch(createBatch(selectLPATypeState === 0 ? "FL" : "SL", userType)).then(() => {})
    };
  }, [selectLPATypeState])

  useEffect(() => {
    if (typeof batchId !== "undefined") {
      dispatch(getSheetsInBatch(batchId))
    };
  }, [])

  useEffect(() => {
    var flag = 0;
    for (var i = 0; i < uploadedFiles.length; i++) {
      if (uploadedFiles[i].sheet_quality === false) {
        flag = 1
        setEnterNikshayIdsButtonState(true)
        break
      }
    }
    if (flag === 0) {
      setEnterNikshayIdsButtonState(false)
    }
  }, [uploadedFilesChangeTracker, uploadedFiles])

  useEffect(() => {
    if (qualityCheckPassed === false) {
      if (fileQualityPayloadError?.msg_code === "duplicate_sheet") { // Duplicate sheet
        logSheetUploadedEvent(true)
        setErrorHeading("You have already uploaded this sheet");
        setErrorContent("You can not upload the same sheet twice.");
        handleErrorDialogOpen();
      } else { // same batch
        logSheetUploadedEvent(false)
        setErrorHeading("Server error");
        setErrorContent("Please reload the page and try again.");
        handleErrorDialogOpen();
      }
    } else if (qualityCheckPassed === true) {
      logSheetUploadedEvent(false)
    }
  }, [dispatch, fileQualityPayload])

  useEffect(() => {
      setUploadedFiles(initiatedBatch?.sheets || []);
  }, [initiatedBatch])
  
  useEffect(() => {
    if (selectLPATypeState !== -1) {
      setBatchType(selectLPATypeState === 0 ? "FL" : "SL");
      }
    else if (initiatedBatch?.batch_type) {
      setBatchType(initiatedBatch?.batch_type);
    }
}, [initiatedBatch, selectLPATypeState])

  const handleErrorDialogClose = (value) => {
    setErrorDialogOpen(false);
  };

  useEffect(() => {
    if (scanResponse?.success) { dispatch(setQualityCheck(scanResponse.data)); } else if (scanResponse?.success === false) { dispatch(setQualityCheckError(scanResponse.detail)); }
  }, [scanResponse])

  const [selectedImageIndexForPreview, setSelectedImageIndexForPreview] = useState(0)
  const handleImagePreviewOpen = (index) => {
    logPreviewSheetEvent(ScreenNames.UPLOAD)
    setImagePreview(true);
    setSelectedImageIndexForPreview(index)
  };

  const handleImagePreviewClose = (value) => {
    setImagePreview(false);
  };

  // function checkIfEnterNikshayIDValid (uploadedFiles) {
  //   var flag = 0;
  //   for (var i = 0; i < uploadedFiles.length; i++) {
  //     if (uploadedFiles[i].sheet_quality === false) {
  //       flag = 1
  //       setEnterNikshayIdsButtonState(true)
  //       break
  //     }
  //   }
  //   if(flag === 0) {
  //     setEnterNikshayIdsButtonState(false)
  //   }
  // }

  async function handleUpload (event) {
    if (event.target.files[0]) {
      setQcLoading(true)
    await dispatch(getQualityCheck(batchId, event.target.files[0])).then(() => {
        setQcLoading(false);
    })
    }
    setUploadedFilesChangeTracer(!uploadedFilesChangeTracker)
  };

  async function handleReuploadClick (event) {
    dispatch(deleteSheet({ sheet_id: reuploadSheetId })).then(() => dispatch(getSheetsInBatch(batchId)))
    var newFiles = uploadedFiles.filter(item => item.sheet_id !== reuploadSheetId)
    setUploadedFiles(newFiles);
    setReuploadSheetId(-1);
    // filesToDisplay.push(currentFile)
    // setFilesToDisplay(filesToDisplay)
    setQcLoading(true)
    // setUploadedImage(URL.createObjectURL(event.target.files[0]))
    // setReupload(0)
    await dispatch(getQualityCheck(batchId, event.target.files[0])).then(() => {
        setQcLoading(false);
    })
    setUploadedFilesChangeTracer(!uploadedFilesChangeTracker)
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  function handleClose () {
    setDeleteSheetId(-1);
  };

  function handleDelete () {
    dispatch(deleteSheet({ sheet_id: deleteSheetId })).then(() => dispatch(getSheetsInBatch(batchId)))
    var newFiles = uploadedFiles.filter(item => item.sheet_id !== deleteSheetId)
    setUploadedFiles(newFiles);
    setDeleteSheetId(-1);
    setUploadedFilesChangeTracer(!uploadedFilesChangeTracker)
  };

  function handleNext () {
    setSubmitLoading(true)
    dispatch(getBatchRoi(batchId)).then(() => {
      if(demo){
        var sheets = []
        var count = 0
        var sheetRows = ["Positive control", "Mastermix control", "Extraction control"]
        uploadedFiles.forEach((sheet) => {
          while(sheetRows.length < 24){
            sheetRows.push(count)
            count += 1
          }
          sheets.push({
            sheet_id: sheet.sheet_id,
            rows: sheetRows
          })
          sheetRows = []
        })
        dispatch(submitLabIdsForBatch(batchId, sheets)).then(
          (res) => {
            const batchValidityData = res
            let updateRows = []
            batchValidityData.control_strips.forEach((strip) => {
              let bandArray = strip.interpreted_band_array
              if(strip.strip_type === 'positive control')
              {
                bandArray[0] = 1
                bandArray[1] = 1
                bandArray[2] = 1
              }
              else if(strip.strip_type === 'extraction control')
              {
                bandArray[0] = 1
                bandArray[1] = 1
                bandArray[2] = 0
              }
              else if(strip.strip_type === 'mastermix control')
              {
                bandArray[0] = 1
                bandArray[1] = 1
                bandArray[2] = 0
              }
              updateRows.push({ row_id: strip.row_id, reviewed_band_array: bandArray })
            })
            dispatch(getInterpretation(batchId, updateRows)).then(() => { setState("i") })
          }
        )
      }
      else{
        setSubmitLoading(false);
        setState("m")
      }
    })
  }

  function handleBack () {
      setState("d");
  }
  const [showHelpState, setshowHelpState] = useState(true);
  function handleShowHelp () {
    setshowHelpState(!showHelpState)
  }

  return (
    <React.Fragment>
      { (batchId === null || batchId === undefined) && <SelectLPATypeDialog selectLPADialog={selectLPADialog} setState={setState} setSelectLPADialog={setSelectLPADialog} setSelectLPATypeState={setSelectLPATypeState}/>}
      <CssBaseline/>
      <AppBar mbReview={false}/>
      <ScannerInstructionsDialog showDialog={showScannerDialog} setShowDialog={setShowScannerDialog} />
      <BreadCrumbList pageList={pageList} setState={setState} warningMessage={null} />
      {imagePreview && <ImageCarousel
        open={imagePreview}
        onClose={handleImagePreviewClose}
        images={uploadedFiles.map(row => row?.transformed_image_location ? row.transformed_image_location : row.original_image_location)}
        index = {selectedImageIndexForPreview}
      />}
      <TipsSheetComponent key={showHelpState} currentPageTitle={PageTitle.upload}/>
      {submitLoading &&
      <Backdrop className={classes.backdrop} open={submitLoading}>
        <CircularProgress/>
      </Backdrop>
      }
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Issue Reported, Please upload another sheet."
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            style={{ padding: "1px" }}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        }
      /> */}

    <Grid container className={classes.container} style={{ background: "white", paddingTop: "20px" }} justifyContent="center">
        <Grid item xs={12} md={8} lg={8}>
            <Paper style={{ border: "1px solid #DADCE0" }} >
                <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: "20px" }}>
                    <div className={classes.heading} style={{ paddingTop: "30px" }}>
                            {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => setState("d")}/> */}
                            <ArrowBackIcon onClick={handleBack} style={{ color: "#1A73E8", cursor: "pointer" }}></ArrowBackIcon>
                            <Typography style={{
                            paddingLeft: "10px",
                            fontSize: "32px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            letterSpacing: "0.1px",
                            alignItems: "center",
                            color: "#202124"
                            }} gutterBottom>
                            Upload/Scan LPA Sheet
                            </Typography>
                            <HelpOutlineIcon onClick={handleShowHelp} style={{ marginLeft: "15px", cursor: "pointer", color: "#555770" }}></HelpOutlineIcon>

                    </div>
                </Grid>
                <Grid container spacing={3} style={{ padding: "20px" }}>
                    <Grid item xs={6} md={6} lg={6} >
                        <Paper className={classes.paper} elevation={0} style={{ backgroundColor: "#F8F9FA" }}>
                            <div className={classes.rightSideDiv}>
                              {/* <PublishIcon style={{ fontSize: 100, color: "#DEE0E4" }}/> */}
                              <img src={require("../constants/upload.png")} alt="Logo" />
                              <Typography align="center" style={{
                                color: globalColors.brandColor1,
                                fontSize: "18px",
                                fontWeight: "500",
                                lineHeight: "35px",
                                letterSpacing: "0.1px",
                                paddingTop: "10px"
                              }} gutterBottom>
                              Drag and Drop here <br/> OR
                              </Typography>

                                <div style={{ justifyContent: "center", }}>
                                <Button disabled={qcLoading} className={globalClasses.blueButton} variant="contained" component="label"
                                        startIcon={<PublishIcon></PublishIcon>}>
                                Browse
                                <input
                                    accept="image/png"
                                    className={classes.input}
                                    id="contained-button-file"
                                    type="file"
                                    hidden
                                    onChange={handleUpload}
                                    onClick={(event) => { event.target.value = '' }}
                                />
                                </Button>

                                <DWT batchId={batchId} qcLoading={qcLoading} setQcLoading={setQcLoading} setScanResponse={setScanResponse} setScannerCount={setScannerCount}/>
                                {/* <Button disabled={qcLoading} className={classes.button} variant="contained" component="label"
                                        startIcon={<ScannerIcon></ScannerIcon>}>
                                Scan
                                </Button> */}

                                </div>

                                {erroDialogOpen
                                ? <ErrorDialog
                                    open={erroDialogOpen}
                                    onClose={handleErrorDialogClose}
                                    errorHeading={errorHeading}
                                    errorContent={errorContent}
                                /> : ''}
                                {
                                  scannerCount === 0 &&
                                <Typography align="center" style={{
                                color: "#FF5757",
                                fontSize: "13px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "0.15px",
                                paddingTop: "10px"
                                }} gutterBottom>
                                Scanner not detected. Follow these <a style={{ color: '#2B78C2' }} onClick={() => setShowScannerDialog(true)}>steps </a> to connect your scanner
                                </Typography>
                                }

                                <Typography align="center" style={{
                                fontStyle: "Italic",
                                color: "#8F90A6",
                                fontSize: "13px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "0.15px",
                                paddingTop: "10px"
                                }} gutterBottom>
                                Supported file extensions: PNG or TIFF
                                </Typography>

                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} >
                        <Paper className={classes.paper} elevation={0}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div className={classes.c1}>
                                        <Typography
                                        component={'span'}
                                        style={{
                                            color: globalColors.textColor3,
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            lineHeight: "21px",
                                            letterSpacing: "0.15px"
                                        }} gutterBottom>
                                            <ul><li>Please upload sheets from the same batch. </li></ul>
                                        </Typography>
                                        <Typography
                                        component={'span'}
                                        style={{
                                            color: globalColors.textColor3,
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            lineHeight: "21px",
                                            letterSpacing: "0.15px"
                                        }} gutterBottom>
                                            <ul><li>Ensure that the sheet is not tilted while scanning and that the scanner settings are correct </li></ul>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant="caption" display="block" style={{
                                        paddingLeft: "10px",
                                        paddingBottom: "10px",
                                        fontSize: "20px",
                                        fontWeight: "400",
                                        lineHeight: "35px",
                                        letterSpacing: "0.1px",
                                        color: globalColors.textColor1
                                        }} gutterBottom>
                                        Uploaded/Scanned sheets ({uploadedFiles.length})
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant="caption" display="block" style={{
                                            paddingLeft: "10px",
                                            paddingBottom: "30px",
                                            fontSize: "15px",
                                            lineHeight: "16px",
                                            letterSpacing: "0.1px",
                                            fontWeight: "400"
                                            }} gutterBottom>
                                            LPA Batch Type: <Box component="span" fontWeight='700'>{batchType === "FL" ? "First Line" : "Second Line"}</Box>
                                        </Typography>

                                        {/* <Button className={classes.reportButton}
                                            onClick={() => setReportDialog(true)}> REPORT
                                            <ReportDialog reportDialog={reportDialog} sheetId={currentFile.sheet_id}
                                            setReportDialog={setReportDialog} setOpenSnackbar={setOpenSnackbar}
                                            />
                                        </Button> */}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                {/* Image uploads */}
                                    {uploadedFiles.map((row, i) => (

                                        <Paper style={{ border: "1px solid #DADCE0", margin: "5px", key: i + 1 }} >

                                        <Grid container alignItems="center">
                                            <Grid item xs={2} md={2} lg={2} style={{ height: "100px", padding: "5px" }}>
                                                <Paper style={{ height: "90px", overflow: "hidden" }}>
                                                <img onClick={() => handleImagePreviewOpen(i)} alt="Uploaded file"
                                                src={row.thumbnail_image_location !== "" ? row.thumbnail_image_location : row.original_image_location}
                                                    style={{ width: "100%", cursor: "pointer" }}/>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={10} md={10} lg={10} >
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div>

                                                    <Typography style={{
                                                    fontSize: "15px",
                                                    fontWeight: "400",
                                                    lineHeight: "16px",
                                                    letterSpacing: "0.15px",
                                                    marginLeft: "5px"
                                                    }}>Sheet {i + 1}</Typography>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                                    {row.sheet_quality === false &&
                                                    <div>
                                                    <Button disabled={qcLoading}  variant="text" component="label" onClick={() => {
                                                      setReuploadSheetId(row.sheet_id)
                                                      }} style={{ cursor: "pointer", color: "#2B78C2" }}>
                                                      Re-upload
                                                      <input
                                                          accept="image/png"
                                                          className={classes.input}
                                                          id="contained-button-file"
                                                          type="file"
                                                          hidden
                                                          onChange={handleReuploadClick}
                                                      />
                                                      </Button>
                                                      </div>
                                                    }
                                                    <DeleteIcon style={{ color: "#FF5757", cursor: "pointer", marginRight: "11px", marginBottom: "5px" }} onClick={() => {
                                                    setDeleteSheetId(row.sheet_id)
                                                    }}/>
                                                    </div>
                                                    <Dialog open={deleteSheetId > -1} onClose={handleClose} className={classes.dialog}>
                                                    <Paper elevation={0} style={{ width: "546px", height: "200px", display: "flex", flexDirection: "column" }}>
                                                        <Typography classes={classes.dialogContent} style={{paddingTop: "28px", paddingLeft: "24px", paddingBottom: "31px"}}>
                                                        Are you sure you want to delete this sheet?
                                                        </Typography>
                                                    <Divider variant="middle" style={{marginLeft: "24px"}}/>
                                                    <DialogActions className={classes.dialogueActions} style={{paddingTop: "29px"}}>
                                                        <Button variant="outlined" onClick={handleClose} className={globalClasses.cancelButton}>
                                                        Cancel
                                                        </Button>
                                                        <Button onClick={handleDelete} variant="contained" className={globalClasses.dialogButton}
                                                                startIcon={<DeleteOutlineIcon></DeleteOutlineIcon>}>
                                                        Delete
                                                        </Button>
                                                    </DialogActions>
                                                    </Paper>
                                                    </Dialog>
                                                </div>
                                                <Grid style={{ marginLeft: "5px", marginRight: "11px" }}> <BorderLinearProgress variant="determinate" value={100}/></Grid>
                                                <div style={{ display: "flex" }}>
                                                <Typography style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    lineHeight: "16px",
                                                    letterSpacing: "0.1px",
                                                    margin: "5px",
                                                    color: row.sheet_quality ? "#48D292" : "#FF5757",
                                                    }}>Image quality: {row.sheet_quality ? "Good" : "Bad"}</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        </Paper>

                                    ))}
                                    { qcLoading &&
                                      <Paper style={{ border: "1px solid #DADCE0", margin: "5px" }} >
                                        <Grid container alignItems="center">
                                            <Grid item xs={2} md={2} lg={2} style={{ height: "100px", padding: "5px" }}>
                                                <Paper style={{ height: "90px", overflow: "hidden" }}>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={10} md={10} lg={10} >
                                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                                                <Typography style={{
                                                    fontSize: "15px",
                                                    fontWeight: "400",
                                                    lineHeight: "16px",
                                                    letterSpacing: "0.15px",
                                                    marginLeft: "5px"
                                                    }}>Sheet {uploadedFiles.length + 1}</Typography>
                                                </div>
                                                <Grid style={{ marginLeft: "5px", marginRight: "11px" }}><BorderLinearProgress/></Grid>
                                            </Grid>
                                        </Grid>
                                        </Paper>

                                    }

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
      </Grid>
      <div className={classes.nextStepButton} style={{ background: "white" }}>
        <Button disabled={uploadedFiles.length < 1 || qcLoading === true || enterNikshayIdsButtonState === true} onClick={handleNext} className={globalClasses.blueButton}

                variant="contained" endIcon={<ArrowForwardIcon></ArrowForwardIcon>}>
          Enter Nikshay ID
        </Button>
      </div>

    </React.Fragment>
  );
}

export default Scanner;
