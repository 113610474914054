import React, { useEffect, useState } from "react";
import {
  getBatchInfoForDashboard,
  getUserInfo, setUserId,
 getBatchValidity, setBatchId, getInterpretationData, getSummaryData, getBatchRoi, putMbForBatch, deleteBatch,
 setDemo
} from "../actions/global";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import AppBar from "../components/appBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid";
import globalColors from "../theme/colors";
import globalUseStyle from "../theme/customComponents";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useBreakpoint } from "../utils";
import { logUserDetails } from "../analytics/analyticsHelper";
import { NoEncryption } from "@material-ui/icons";
import PendingTask from "../components/pendingTask";



const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#DADCE0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#DADCE0",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DADCE0",
      },
      "&:hover fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px",
      }
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    letterSpacing: "0.1px",
    alignItems: "center"
  },
  paper: {
    padding: theme.spacing(2),
    elevation: "0",
    display: "flex",
    flexDirection: "column",
    background: "#F7F9FB",
  },
  dashboardTextContainer: {
    backgroundColor: globalColors.backgroundColor1,
    padding: 20,
  },
  dashboardText: {
    textAlign: "left",
    paddingLeft: "20px",
    paddingTop: "30px",
    paddingBottom: "15px",
    color: globalColors.textColor1
  },
  startTileContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: globalColors.backgroundColor1
  },
  leftPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 360,
    backgroundColor: globalColors.backgroundColor2
  },
  pastInterpretationText: {
    paddingTop: "10px",
    width: "300px",
    textAlign: "left",
    color: globalColors.textColor1
  },
  pendingTile: {
    color: "white",
    fontWeight: "500",
    display: "flex",
    alignContent: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    padding: '5px',
    backgroundColor: "#FE7A10",
    borderRadius: "5px"
  },
  deleteButton: { 
    zIndex: 4,
    fontSize: 30,
    pointerEvent: 'none',
    color: globalColors.semanticColor4, 
    cursor: "pointer", 
    display: 'flex',
    alignContent: "center",
    position: "absolute",
    top: "10px",
    right: "10px",
    padding: '5px',
    backgroundColor: "white",
    borderRadius: "5px",
  },
  nextStepButton: {
    display: "flex",
    paddingTop: 300,
    flexDirection: "column",
    alignItems: "center",
  },
  rightSideDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 280,
  },
  c1: {
    paddingTop: 5,
  },
  heading: {
    display: "flex",
    justifyContent: "flex-start",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 180
  },
  interpretSheetsGuideText: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    alignItems: "center",
    marginTop: "21px",
    fontSize: "15px",
    fontWeight: "400",
  },
  interpretGuideGrid: {
    display: "flex",
    flexDirection: "column",
    // textAlign: "center",
    // width: "70px",
    margin: "5px"
  },
}));

export default function Album ({ userId, demo, setState }) {
  const classes = useStyles();
  const globalClasses = globalUseStyle();
  const breakpoint = useBreakpoint();
  // const history = useHistory();
  const dispatch = useDispatch();
  const click = React.useRef(null)
  // const [batchType, setBatchType] = React.useState(0);
  const [batchTypeFilter, setBatchTypeFilter] = React.useState(2);
  const userType = useSelector(state => state.globalActionsReducer.user_type);
  const globalBatchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id;
  const [selectedBatchState, setSelectedBatchState] = React.useState("")
  const [dateTypeFilter, setDateTypeFilter] = React.useState("");
  const [batchData, setBatchData] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState(null);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [deleteBatchId, setDeleteBatchId] = useState(-1);
  const dashboardRows = useSelector(
    (state) => state.globalActionsReducer.batch_info_dashboard
  )?.batches;
  const userInformation = useSelector(
    (state) => state.globalActionsReducer.user_info
  );

  const taskData = [
    {nikshay_id: 1, status: "invalid", date: "01/01/2000", batch_id: 1},
    {nikshay_id: 2, status: "invalid", date: "01/01/2000", batch_id: 1}
  ]
  
  React.useEffect(() => {
    dispatch(getUserInfo(userType)).then(() => {
      dispatch(setDemo(demo))
      dispatch(setUserId(userId))
      dispatch(getBatchInfoForDashboard(userType));
    });
    logUserDetails(userId);

  }, [dispatch, userId, userType]);

  React.useEffect(() => {
    setBatchData(dashboardRows);
  }, [dashboardRows]);

  useEffect(() => {
    if (dashboardRows === undefined) return;
    let filtered = dashboardRows?.filter((batch) =>
      batchTypeFilter === 2
        ? true
        : batch.batch_type === (batchTypeFilter === 0 ? "FL" : "SL")
    );
    if (dateTypeFilter) {
      filtered = filtered.filter(
        (batch) => batch.updated_at === dateTypeFilter
      );
    }
    setFilteredBatches(filtered);
  }, [batchTypeFilter, dateTypeFilter, dashboardRows]);

  React.useEffect(() => {
    setUserInfo(userInformation);
    window.sessionStorage.setItem("name", userInformation?.name);
    window.sessionStorage.setItem("lab_name", userInformation?.lab_name);
    window.sessionStorage.setItem("designation", userInformation?.designation);
    window.sessionStorage.setItem("user_id", userId);
  }, [userId, userInformation]);


  React.useEffect(() => {
    if (globalBatchId !== undefined) {
      if (selectedBatchState === 'initialised') {
        setState('u')
      } else if (selectedBatchState === 'interpreted') {
        dispatch(getBatchRoi(globalBatchId)).then(() => { setState('m') })
      } else if (selectedBatchState === 'validate_lt' || selectedBatchState === 'validate_mb') {
        dispatch(getBatchValidity(globalBatchId)).then(() => { setState('b') })
      } else if (selectedBatchState === 'review_in_progress_lt' || selectedBatchState === 'review_in_progress_mb') {
        dispatch(getInterpretationData(globalBatchId)).then(() => { setState('i') })
      } else if (selectedBatchState === 'reviewed_lt' || selectedBatchState === 'reviewed_mb') {
        dispatch(getSummaryData(globalBatchId)).then(() => { setState('s') })
      } else if (selectedBatchState === 'sample_info_lt') {
        setState('c')
      }else if (selectedBatchState === 'sample_info_mb') {
        setState('t')
      } else if (selectedBatchState === 'pending_for_mb') {
        dispatch(putMbForBatch(globalBatchId)).then(setSelectedBatchState('validate_mb'))
      } else if (selectedBatchState === 'completed') {
        dispatch(getSummaryData(globalBatchId)).then(() => setState('f'));
      }
    }
  }, [dispatch, globalBatchId, selectedBatchState, userId, setState])

  function startLPABatch () {
    // dispatch(createBatch(batchType === 0 ? "FL" : "SL", userId)).then(() => {
    //   setState("u");
    //   // history.push('/upload')
    // });
    dispatch(setBatchId(null))
    setState("u");
  }
  

  const handleDropDownChangeFilter = (event) => {
    setBatchTypeFilter(event.target.value);
  };
  const handleDateChange = (event) => {
    setDateTypeFilter(event.target.value);
  };

  function resumeBatch (batchId, batchState) {
    setSelectedBatchState(batchState);
      dispatch(setBatchId({ batch_id: String(batchId) }))
  }

  function checkEditPossible (batchState, mbReview) {
    if (userInformation?.designation === 'Lab technician') {
      if (mbReview) { return false } else { return true }
    } else if (userInformation?.designation === 'Microbiologist') {
      if (batchState === 'completed') { return false } else { return true }
    }
  }

  function checkDeletePossible (mbReview) {
    if (userInformation?.designation === 'Lab technician') {
      if (mbReview) { return false } else { return true }
    } else return false
  }

  function handleDelete() {
    dispatch(deleteBatch({ batch_id: deleteBatchId })).then(() => dispatch(getBatchInfoForDashboard(userType)));
    setDeleteBatchId(-1);
  }

  function handleClose () {
    setDeleteBatchId(-1);
  };

  function pendingTileDisplay (batchStatus, mbReview) {
    if (batchStatus === 'completed') { return '' }
    if (userInformation?.designation === 'Lab technician') {
      if (mbReview) { return 'MB review pending' } else { return '' }
    } else if (userInformation?.designation === 'Microbiologist') {
      if (mbReview) { return 'Review pending' } else { return '' }
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar userInfo={userInfo} mbReview={userType === "mb"}/>
      <Grid container className={classes.dashboardTextContainer} >
        <Typography variant="h1" className={classes.dashboardText} >
          Dashboard
        </Typography>
      </Grid>
      {/* <a href="https://sso.beta.nikshay.in/v1/sso/login?returnUrl=https%3A%2F%2Fbeta-lpa.nikshay.in%2F&clientId=174" ref={click} /> */}
      <Dialog open={deleteBatchId > -1} onClose={handleClose}>
      <Paper elevation={0} style={{ width: "546px", height: "200px", display: "flex", flexDirection: "column" }}>
          <Typography classes={classes.dialogContent} style={{paddingTop: "28px", paddingLeft: "24px", paddingBottom: "31px"}}>
          Are you sure you want to delete this batch?
          </Typography>
        <Divider variant="middle" style={{marginLeft: "24px"}}/>
        <DialogActions style={{paddingTop: "29px"}}>
            <Button variant="outlined" onClick={handleClose} className={globalClasses.cancelButton}>
            Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" className={globalClasses.dialogButton}
                    startIcon={<DeleteOutlineIcon></DeleteOutlineIcon>}>
            Delete
            </Button>
        </DialogActions>
        </Paper>
      </Dialog>
      <Grid container spacing={3} className={classes.startTileContainer} >
        {/* left side */}
        <Grid item xs={12} md={5} lg={3}>
          <Paper className={classes.leftPaper} elevation={3} >
          <Grid container xs={12} md={12} lg={12}>
          <Grid item xs={1} md={1} lg={1}>
          </Grid>
            <Grid container md={11} lg={11} style={{display: "flex", flexDirection: "column"}} >
              <Grid container style={{ display: "flex", alignItems: "center"}} >
                <Grid item style={{marginTop: "33px", marginLeft: "5px"}}>
                  <Typography variant="h2" color={globalColors.textColor1} >
                    LPA Interpretation
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: "30px", marginBottom: "24px" }}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  {/* Maybe Keep items in list and then iterate the list to create components. Will reduce line of codes */}
                  
                  {/* Upload Icon */}
                  <Grid container md={4} lg={4} >
                    <Grid item className={classes.interpretGuideGrid}>
                      <Grid container md={12} lg={12}>
                        <Grid item md={5} lg={5}>
                          <img src={require("../constants/newCloudUploadIcon.svg")} style={{height: "30px"}} alt="Upload Icon" />
                        </Grid>
                        <Grid item md={5} lg={5} alignItems="center" style={{marginLeft: "10px", marginRight: "4px", marginTop: "5px"}}>
                          <img src={require("../constants/rightArrowIcon.svg")} alt="Right Arrow"/>
                        </Grid>
                      </Grid>
                      <div className={classes.interpretSheetsGuideText}>
                      Upload/Scan sheets
                      </div>
                    </Grid>
                  </Grid>
                  
                  {/* AI interpretation Icon */}
                  <Grid container md={4} lg={4} >
                    <Grid item className={classes.interpretGuideGrid}>
                      <Grid container md={12} lg={12}>
                        <Grid item md={5} lg={5}>
                          <img src={require("../constants/newInterpretationIcon.svg")} style={{height: "34px"}} alt="Interpretation Icon"/>
                        </Grid>
                        <Grid item md={5} lg={5} alignItems="center" style={{ marginLeft: "10px", marginRight: "4px", marginTop: "5px", }} >
                          <img src={require("../constants/rightArrowIcon.svg")} alt="Right Arrow" />
                        </Grid>
                      </Grid>
                      <div className={classes.interpretSheetsGuideText}>
                        AI gives interpretation
                      </div>
                    </Grid>
                  </Grid>
                  
                  {/* Review Interpretation Icon */}
                  <Grid container md={3} lg={3} >
                    <Grid item className={classes.interpretGuideGrid}>
                    <Grid container md={12} lg={12}>
                      <Grid item md={5} lg={5}>
                        <img src={require("../constants/newReviewIcon.svg")} style={{height: "35px",}} alt="Review Icon" />
                      </Grid>
                      <Grid item md={5} lg={5}>
                      </Grid>
                    </Grid>
                      <div className={classes.interpretSheetsGuideText}>
                        Review interpretation
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div
                style={{
                  alignItems: "center",
                  // justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  className={globalClasses.blueButton} onClick={startLPABatch} variant="contained" >
                  Start LPA Interpretation
                </Button>
              </div>
            </Grid>
            
            </Grid>
          </Paper>
          <Grid item xs={12} md={12} lg={12} style={{marginTop: "20px"}}>
            <PendingTask output={dashboardRows} setState={setState}/>
          </Grid>
        </Grid>
        {/* right side */}
        <Grid item xs={12} md={7} lg={8}>
          <Paper elevation={3} className={classes.paper}>
            <Grid
              container
              spacing={2}
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  paddingBottom: "20px",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="h2" className={classes.pastInterpretationText} >
                  Past Interpretations
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                    alignItems: "end",
                  }}
                >
                  <CssTextField
                    id="date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={dateTypeFilter}
                    onChange={handleDateChange}
                    className={classes.textField}
                    style={{ width: "208px", background: globalColors.backgroundColor1, }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      style: { color: globalColors.textColor3}
                    }}
                  />
                  <CssTextField
                    select
                    size="small"
                    variant="outlined"
                    // value={batchTypeFilter}
                    displayEmpty
                    // defaultValue={1}
                    label={"LPA Type"}
                    onChange={handleDropDownChangeFilter}
                    // placeholder="LPA Type"
                    style={{ width: "226px", marginLeft: "20px", background: globalColors.backgroundColor1 }}
                    InputLabelProps={{
                      style: { color: globalColors.textColor3 },
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      FL
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      SL
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      All
                    </MenuItem>
                  </CssTextField>
                </div>
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <Grid
                  container
                  spacing={2}
                  // style={{ height: "600px", overflow: "auto" }}
                >
                  {batchData &&
                    filteredBatches.map((row, i) => (
                      <Grid item key={i} xs={12} sm={6} md={6} lg={3}>
                        <Paper
                          elevation={0}
                          style={{
                            border: "1px solid #DADCE0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "350px",
                            width: "200px",
                            background: "#F7F9FB"
                          }}
                        >
                        {/* <Button onClick={() => resumeBatch(row.batch_id, row.status)} disabled={row.status !== 'completed'}> */}
                          <div style={{ height: "250px", width: "200px", position: "relative" }}>
                            <img
                              // onClick={row.status==='completed' ? () => resumeBatch(row.batch_id, row.status) :  () => {}}
                              alt="Uploaded file"
                              src={row.thumbnail_image_location}
                              onMouseOver={(e) => { if (row.status === 'completed') e.target.style.opacity = 0.5}}
                              onMouseLeave={(e) => { if (row.status === 'completed') e.target.style.opacity = 1}}
                              style={{
                                width: "100%",
                                height: "100%",
                                padding: "10px",
                              }}
                              onClick={() => {
                                if (row.status !== 'completed')
                                  return
                                else
                                  resumeBatch(row.batch_id, row.status);
                                }}
                            />
                            {
                              checkDeletePossible(row.mb_review) &&
                              <IconButton className={classes.deleteButton}>
                              <DeleteIcon  onClick={() => {setDeleteBatchId(row.batch_id)}}/>
                              </IconButton>
                            }
                            {
                              pendingTileDisplay(row.status, row.mb_review) !== '' &&
                              <div className={classes.pendingTile}>
                              {pendingTileDisplay(row.status, row.mb_review)}
                              </div>
                            }
                            
                          </div>
                          {/* </Button> */}
                          <Typography
                            style={{
                              paddingBottom: "15px",
                              fontSize: "15px",
                              fontWeight: "500",
                              lineHeight: "21px",
                              letterSpacing: "0.1px",
                              color: globalColors.textColor1
                            }}
                          >
                            {" "}
                            {moment(row.updated_at).format(
                              "DD MMM YYYY"
                            )}{" "}
                          </Typography>
                          <Typography
                            style={{
                              // paddingBottom: "3px",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18px",
                              letterSpacing: "0.1px",
                              color: globalColors.textColor1
                            }}
                          >
                            {row.number_of_samples} {row.batch_type} LPA samples
                          </Typography>
                          { checkEditPossible(row.status, row.mb_review)

                          ? <Button onClick={() => resumeBatch(row.batch_id, row.status)} style={{ color: row.mb_review === true ? globalColors.brandColor1 : globalColors.brandColor2 }}>

                          <EditIcon style={{ color: row.mb_review === true ? globalColors.brandColor1 : globalColors.brandColor2 , fontSize: "14" }}/>

                          {
                            row.mb_review === true ?
                            <Typography style={{
                              paddingLeft: "4px",
                              fontSize: "15px",
                              fontWeight: "500",
                              lineHeight: "18px",
                              letterSpacing: "0.1px",}}>
                              Review
                            </Typography>  
                          : 
                          <Typography style={{
                            paddingLeft: "4px",
                            fontSize: "15px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            letterSpacing: "0.1px",}}>
                            Incomplete review
                          </Typography>
                          }
                          </Button>
                          : row.status === 'completed' ? "MB Review Completed" : row.mb_review ? 'MB review in Progress' : ""

                          }
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
