import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { getDistricts, getLabs, getNikshayIds, getAllSampleInfo, getStates, getSummaryData, putSampleFinalSubmission, putSampleinfo, getHierarchies, submitAPI } from "../actions/global";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AppBar from "../components/appBar";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BreadCrumbList from "../components/breadCrumbList";
import Button from "@material-ui/core/Button";
import { ButtonGroup } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
// import FormLabel from '@material-ui/core/FormLabel';
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import globalColors from "../theme/colors"
import globalUseStyle from "../theme/customComponents";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/TableBody';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";

// import { logUserDetails } from "../analytics/analyticsHelper";
// import moment from "moment";


const useStyles = makeStyles((theme) => ({
    
    container: {
        paddingLeft: 40,
        paddingRight: 40,
    },
    dashboardContainer: {
        padding: 20,
    },
    nextStepButton: {
        display: "flex",
        paddingTop: 30,
        flexDirection: "column",
        alignItems: "center",
        "& .MuiButton-contained.Mui-disabled": {
            backgroundColor: "white"
        }
    },
}));
const SampleSummaryTable = ({ setState }) => {
    const classes = useStyles();
    const globalClasses = globalUseStyle();
    const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
    let batchRoi = useSelector(state => state.globalActionsReducer.batch_roi)?.sheets;
    let allSampleInfo = useSelector(state => state.globalActionsReducer.all_sample_info)?.sample_list;
    const [errorState, setErrorState] = useState(false)
    const [selectedStates, setSelectedStates] = useState([])
    const [selectedDistricts, setSelectedDistricts] = useState([])
    const [selectedLabs, setSelectedLabs] = useState([])
    const [selectedReasons, setSelectedReasons] = useState([])
    const [selectedTypeDRTB, setSelectedTypeDRTB] = useState([])
    const [selectedSampleTypes, setSelectedSampleTypes] = useState([])
    const [selectedSampleDesc, setSelectedSampleDesc] = useState([])


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
    setAnchorEl(null);
    };
    const dispatch = useDispatch();
    let hierarchies = {}

    var [states, setStates] = useState([])
    // let states = []
    var [districts, setDistricts] = useState({})
    var [labs, setLabs] = useState({})
    // let districts = {}
    // let labs = {}

    const pageListLT = ['Upload', 'Enter Nikshay IDs', 'Check Controls', 'Review Interpretation', 'Summary', 'Sample Information']
    const pageListMB = ['Check Controls', 'Review Interpretation', 'Summary', 'Sample Information']


    const reasonsForTesting = ["Diagnosis of DRTB", "Follow up of DRTB"]
    const typesOfPresumptiveDRTB = ["At Diagnosis", "Contact of MDR/RRTB", "Any Follou-up +ve", "Private Referral", "Presumptive H Mono/Poly", "MDR/RRTB at Diagnosis", "Discordance Resolution", "Failure of recurrance of MDR/RR-TB regimen", "Follow up culture Positive"]
    const sampleTypes = ["Sputum", "Other"]
    const sampleDescriptions = ["Mucopurulent", "Blood Stained", "Saliva"]

    React.useEffect(() => {
        console.log("satete", selectedStates)
    }, [selectedStates])


    React.useEffect(() => {
        dispatch(getHierarchies(batchId)).then((res) => {
            districts = {}
            labs = {}
            states = res?.list_of_states.map((state) => {
                let districtList = state.districts.map((district) => {
                    let labList = district.labs.map((lab) => {
                        let labDto = {lab_id: lab.lab_id, name: lab.lab_name}
                        return labDto
                    })
                    labs[district.district_id] = labList
                    let districtDto = {district_id: district.district_id, name: district.district_name}
                    return districtDto
                })
                districts[state.state_id] = districtList
                let stateDto = {state_id: state.state_id, name: state.state_name}
                return stateDto
            })
            setStates(states)
            setDistricts(districts)
            setLabs(labs)
        })
    }, [])

    const [mbReview, setMbReview] = useState(true)

    const [sampleList, setSampleList] = React.useState([1, 4, 6])

    React.useEffect(() => {
        var initSelectedStates = []
        var initSelectedDistricts = []
        var initSelectedLabs = []
        var initSelectedReasons = []
        var initSelectedTypeDRTB = []
        var initSelectedSampleTypes = []
        var initSelectedSampleDesc = []
        
        allSampleInfo.map((sample) => {
            console.log("sample", sample)
            states.forEach( it => {
                console.log("comp", it.name, sample.collection_site_state.name)
            })
            initSelectedStates.push( states.findIndex( it => it.name === sample.collection_site_state.name) )
            // selectedDistricts.push(districts[sample.collection_site_state.id].findIndex(it => it.name === sample.collection_site_district.name))
            // initSelectedDistricts.push(0)
            // initSelectedLabs.push(sample.collection_site_lab)
            initSelectedReasons.push(reasonsForTesting.findIndex( it => it === sample.reason_for_testing))
            initSelectedTypeDRTB.push( typesOfPresumptiveDRTB.findIndex( it => it === sample.type_of_presumptive_DRTB))
            initSelectedSampleTypes.push( sampleTypes.findIndex( it => it === sample.sample_type))
            initSelectedSampleDesc.push(sampleDescriptions.findIndex( it => it === sample.sample_description))
        })
        setSelectedStates([...initSelectedStates])
        setSelectedDistricts([...initSelectedDistricts])
        setSelectedLabs([...initSelectedLabs])
        setSelectedReasons([...initSelectedReasons])
        setSelectedTypeDRTB([...initSelectedTypeDRTB])
        setSelectedSampleTypes([...initSelectedSampleTypes])
        setSelectedSampleDesc([...initSelectedSampleDesc])


    }, [allSampleInfo])


    React.useEffect(() => {
        let initSelectedStates = []
        allSampleInfo.map((sample) => {
            initSelectedStates.push( states.findIndex( it => it.name === sample.collection_site_state.name) )
        })
        setSelectedStates([...initSelectedStates])
    
    }, [states])

    React.useEffect(() => {
        if(states){

            let initSelectedDistricts = []
            allSampleInfo.map((sample, index) => {
                if(selectedStates[index] >= 0){
                    initSelectedDistricts.push( districts[states[selectedStates[index]].state_id]?.findIndex( it => it.name === sample.collection_site_district.name) )
                }
                else{
                    initSelectedDistricts.push()
                }
            })
            console.log("fin", initSelectedDistricts)
            setSelectedDistricts([...initSelectedDistricts])
        }
    
    }, [districts, selectedStates])

    React.useEffect(() => {
        if(districts){
            let initSelectedLabs = []
            allSampleInfo.map((sample, index ) => {
                // console.log("debug", labs[selectedDistricts[index]?.district_id], selectedDistricts[index]?.district_id, labs, selectedDistricts)
                if(selectedDistricts[index] >= 0){
                    let state_id = states[selectedStates[index]].state_id
                    let district_id = districts[state_id][selectedDistricts[index]].district_id
                    
                    initSelectedLabs.push( labs[district_id].findIndex( it => it.name === sample.collection_site_lab.name) )
                }
                else{
                    initSelectedLabs.push()
                }
            })
            console.log("finfin", initSelectedLabs)
            setSelectedLabs([...initSelectedLabs])
        }

        
    
    }, [labs, selectedDistricts])

    React.useEffect(() => {
        console.log("samp", selectedSampleTypes[0])
    }, [selectedReasons])


    function handleNext() {
        dispatch(putSampleFinalSubmission(batchId)).then( (res) => setState('d'))
    }
    
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar mbReview={true}/>
      <Grid
        container
        className={classes.dashboardContainer}
        style={{ background: "white" }}
      >
      <BreadCrumbList pageList={mbReview ? pageListMB : pageListLT} setState={setState} warningMessage={null} />
        <ArrowBackIcon  style={{ color: "#1A73E8", cursor: "pointer", marginLeft: "15px", marginTop: "20px" }}></ArrowBackIcon>
        <Typography
          style={{
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
            paddingLeft: "20px",
            paddingBottom: "20px",
            paddingTop: "20px"
          }}
        >
          Enter Sample Information
        </Typography>
      </Grid>
      {/* <Grid
        container
        spacing={3}
        // className={classes.container}
        style={{ background: "white" }}
      > */}
      <div style={{ margin: "40px"}}>
        
        <TableContainer >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Table aria-label="customized table" style={{tableLayout: "fixed", width: "100%"}} >
                <TableHead style={{ backgroundColor: "#E5EDFF" }}>
                    <TableRow>
                        <TableCell>Nikshay ID</TableCell>
                        <TableCell>Reason for testing</TableCell>
                        <TableCell>Sample Type</TableCell>
                        <TableCell style={{ minWidth: "200px", maxWidth: "200px"}}>Sample Description</TableCell>
                        <TableCell style={{ minWidth: "250px", maxWidth: "250px"}}>Collection Site State</TableCell>
                        <TableCell style={{ minWidth: "250px", maxWidth: "250px"}}>Collection Site District</TableCell>
                        <TableCell style={{ minWidth: "250px", maxWidth: "250px"}}>Collection Site Lab</TableCell>
                        <TableCell>Collection Date</TableCell>
                        <TableCell>Collection Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {allSampleInfo.map((sample, i) => {
                    return (
                    <TableRow key={i}>
                        <TableCell align='center'>{sample.nikshay_id}</TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                        {selectedReasons[i] !== undefined &&
                        <FormControl fullWidth error={errorState} direction="row" alignItems="center" justify="center">
                                
                                    <Select
                                        disableUnderline={true}
                                        defaultValue={selectedReasons[i]}
                                        label="Select Reason For Testing"
                                        value={selectedReasons[i]}
                                        key={"select reason"}
                                        onChange={(e)=> {
                                            console.log("target value", e.target.value, i)
                                            selectedReasons[i] = e.target.value
                                            setSelectedReasons([...selectedReasons])
                                            }}
                                    >
                                        {
                                            reasonsForTesting.map((reason, index) =>
                                            <MenuItem key={reason} value={index} selected={ reason === selectedReasons[i] } >{reason}</MenuItem>)
                                        }
                                        
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                            </FormControl>
                        }
                        </TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                            { selectedSampleTypes[i] !== undefined &&
                            <FormControl fullWidth error={errorState} direction="row" alignItems="center" justify="center">
                                    <Select
                                        disableUnderline={true}
                                        defaultValue={selectedSampleTypes[i]}
                                        label="Select Sample type"
                                        value={selectedSampleTypes[i]}
                                        key={"select sample type"}
                                        onChange={(e)=> {
                                            selectedSampleTypes[i] = e.target.value
                                            setSelectedSampleTypes([...selectedSampleTypes])
                                            }}
                                    >
                                        {
                                            sampleTypes.map((type, index) =>
                                            <MenuItem key={type} value={index} selected={ type === selectedSampleTypes[i] } >{type}</MenuItem>)
                                        }
                                        
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                            </FormControl>
                            }
                        </TableCell>
                        <TableCell component="th" scope="row" align='center' style={{ minWidth: "200px", maxWidth: "200px"}}> 
                            { selectedSampleDesc[i] !== undefined &&
                            <FormControl fullWidth error={errorState} direction="row" alignItems="center" justify="center">
                                    <Select
                                        disableUnderline={true}
                                        defaultValue={selectedSampleDesc[i]}
                                        label="Select Sample Description"
                                        value={selectedSampleDesc[i]}
                                        key={"select description"}
                                        onChange={(e)=> {
                                            selectedSampleDesc[i] = e.target.value
                                            setSelectedSampleDesc([...selectedSampleDesc])
                                            }}
                                    >
                                        {
                                            sampleDescriptions.map((desc, index) =>
                                            <MenuItem key={desc} value={index} selected={ desc === selectedSampleDesc[i] } >{desc}</MenuItem>)
                                        }
                                        
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                            </FormControl>
                            }
                        </TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                            { selectedStates[i] !== undefined &&
                            <FormControl fullWidth error={errorState} direction="row" alignItems="center" justify="center">
                                <InputLabel style={{color: "black", fontSize: "16px"}}>Select State</InputLabel>
                                    <Select
                                        disableUnderline={true}
                                        defaultValue={selectedStates[i]}
                                        label="Select State"
                                        value={selectedStates[i]}
                                        key={"select state"}
                                        onChange={(e)=> {
                                            selectedStates[i] = e.target.value
                                            setSelectedStates([...selectedStates])
                                            }}
                                    >
                                        {
                                            states.map((stateLis, index) => {
                                                console.log("states", index, stateLis, selectedStates[i])
                                            return (<MenuItem key={stateLis.name} value={index} selected={ stateLis.name === selectedStates[i]?.name } >{stateLis.name}</MenuItem>)
                                            
                                            })
                                            
                                        }
                                        
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                            </FormControl>
                            }
                        </TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                        {
                            selectedDistricts[i] !== undefined  &&
                            <FormControl fullWidth error={errorState} direction="row" alignItems="center" justify="center">
                                <InputLabel style={{color: "black", fontSize: "16px"}}>Select District</InputLabel>
                                    <Select
                                        disableUnderline={true}
                                        defaultValue={selectedDistricts[i]}
                                        label="Select Distrcit"
                                        value={selectedDistricts[i]}
                                        key={i}
                                        onChange={(e)=> {
                                            selectedDistricts[i] = e.target.value
                                            setSelectedDistricts({...selectedDistricts})
                                            }}
                                    >
                                        {
                                            districts[states[selectedStates[i]].state_id]?.map((districtLis, index) =>
                                            <MenuItem key={districtLis.name} value={index} selected={ districtLis.name === selectedDistricts[i]?.name } >{districtLis.name}</MenuItem>)
                                        }
                                        
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                            </FormControl>
                        }
                        </TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                            {
                                selectedLabs[i] !== undefined && 
                            <FormControl fullWidth error={errorState} direction="row" alignItems="center" justify="center">
                                <InputLabel style={{color: "black", fontSize: "16px"}}>Select Labs</InputLabel>
                                    <Select
                                        disableUnderline={true}
                                        defaultValue={selectedLabs[i]}
                                        label="Select Labs"
                                        value={selectedLabs[i]}
                                        key={"select labs"}
                                        onChange={(e)=> {
                                            selectedLabs[i] = e.target.value
                                            setSelectedLabs({...selectedLabs})
                                            }}
                                    >
                                        {
                                            labs[districts[states[selectedStates[i]].state_id][selectedDistricts[i]].district_id]?.map((labLis, index) =>
                                            {
                                                console.log("in map", labLis)
                                             return (   <MenuItem key={labLis.name} value={index} selected={ labLis.name === selectedLabs[i]?.name } >{labLis.name}</MenuItem>)
                                            })
                                        }
                                        
                                    </Select>
                                    {errorState && <FormHelperText>This is required!</FormHelperText>}
                            </FormControl>
                            }
                        </TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                            <Button style={{fontWeight: "400", color: globalColors.textColor3}} onClick={() => {}}>
                                { (new Date(allSampleInfo[i].collection_timestamp)).toLocaleDateString('en-US', dateOptions)} <DateRangeIcon style={{ marginLeft: "20px", color: globalColors.textColor3, cursor: "pointer" }}/>
                            </Button>
                        </TableCell>
                        <TableCell component="th" scope="row" align='center'> 
                            <Button style={{fontWeight: "400", color: globalColors.textColor3}} onClick={() => {}}>
                            { (new Date(allSampleInfo[i].collection_timestamp)).toLocaleTimeString('en-US', timeOptions)} <AccessTimeIcon style={{ marginLeft: "20px", color: globalColors.textColor3, cursor: "pointer" }}/>
                            </Button>
                        </TableCell>
                        
                    </TableRow>
                )
                })}
                </TableBody>
            </Table>
            </div>
        </TableContainer>
        </div>
        
        <div className={classes.nextStepButton} style={{ background: "white" }}>
            <Button disabled={false} onClick={handleNext} className={globalClasses.blueButton} variant="contained" endIcon={<ArrowForwardIcon></ArrowForwardIcon>}>
            {
                mbReview
                ? "Submit"
                : "Share with Microbiologist for review"
            }
            </Button>
        </div>

        {/* </Grid> */}
        </React.Fragment>
    )
}

export default SampleSummaryTable;
