import Button from "@material-ui/core/Button";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import globalColors from '../theme/colors';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getBatchRoi, getPendingRows } from "../actions/global";
import { useDispatch, useSelector } from "react-redux";
import { setBatchId } from "../actions/global";

const useStyles = makeStyles((theme) => ({
    tableData: {
        color: globalColors.textColor1,
        fontSize: "14px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    },
    tableHeader: {
        color: globalColors.textColor1,
        fontSize: "14px",
        fontWeight: "400",
        letterSpacing: "0.15px"
    }
}));

const AntTabs = withStyles({
    indicator: {
    //   backgroundColor: "#1A73E8"
    display: "none"
    }
  })(Tabs);


const AntTab = withStyles((theme) => ({
root: {
    // border: '1px solid #2B78C2',
    textTransform: "none",
    "&:hover": {
    color: "#1A73E8",
    opacity: 1
    },
    "&$selected": {
    backgroundColor: "#E5EDFF",
    color: "#1A73E8",
    fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
    color: "#1A73E8"
    },
    
},
selected: {}
}))((props) => <Tab disableRipple {...props} />);
  

export default function PendingTask ({ output, setState }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [buttonPressed, setButtonPressed] = React.useState(false)
    const [pendingRows, setPendingRows] = React.useState([])
    const [completedRows, setCompletedRows] = React.useState([])
    const [value, setValue] = React.useState(0)
    
    const globalBatchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id;

    React.useEffect(() => {
        dispatch(getPendingRows()).then(res => {
            console.log("pending", res)
            setPendingRows(res.pending_rows)
            setCompletedRows(res.completed_rows)
        })
    }, [output])

    React.useEffect(() => {
        if (globalBatchId !== undefined && buttonPressed) {
            dispatch(getBatchRoi(globalBatchId)).then(() => { setState('m') })
            setButtonPressed(false)
        }
      }, [globalBatchId])

    function resumeBatch (batchId) {
        setButtonPressed(true)
        dispatch(setBatchId({ batch_id: String(batchId) }))
      }
    
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      function createTabHeader(header = "Prioritisation View"){
        return (
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", fontSize: "16px", alignContent: "flex-start", fontWeight: "500" }}>
            <div>{header}</div>
            {/* <span style={{ fontSize: "12px", color: "#202124", textAlign: "center", fontWeight: "400", lineHeight: "1.2" }}>{description}</span> */}
          </div>
        )
      }

    return (
        <>
            
            <Grid item xs={12} md={12} lg={12} style={{ display: "flex", marginBottom: "435px", backgroundColor: "#FCFCFC", padding: "5px", border: "1px solid #DADCE0", flexDirection: "column" }}>
                <div>
                <AntTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} centered >
                    <AntTab label={createTabHeader("Pending Task")} style={{ borderBottomLeftRadius: "8px", borderTopLeftRadius: "8px", width: "50%" }} />
                    <AntTab label={createTabHeader("Complete")} style={{ borderBottomRightRadius: "8px", borderTopRightRadius: "8px", width: "50%" }} />
                </AntTabs>
                </div>
                { value === 0 ?
                pendingRows.length > 0 &&
                <Table padding="none" aria-label="simple table">
                    <TableHead >
                        <TableRow>
                            <TableCell align="center" height="50px" width="30%" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>Nikshay ID Entered</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>Status</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500 }}>Date</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{minHeight: "50px", fontWeight: 500}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {pendingRows.map((row, i) => {
                        return (
                            <TableRow key={i}>
                            <TableCell align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.lab_sample_id === "id not present" ? "ID Not Present": row.lab_sample_id}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.lab_sample_id === "id not present" ? "Blank": "Invalid"}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData} style={{color: globalColors.textColor3}}>{row.updated_at}</Typography>

                            </TableCell>
                            <TableCell align="center" style={{ borderBottom: "1px solid #DADCE0", minHeight: "50px", color: "#DADCE0", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <Button onClick={() => {
                                resumeBatch(row.batch_id)
                            }}>
                            <Typography className={classes.tableData} style={{color: globalColors.brandColor2}}>Update</Typography>
                            <ChevronRightIcon style={{ color: globalColors.brandColor2, cursor: "pointer" }}/>
                            </Button>

                            </TableCell>
                            
                            </TableRow>
                    )
                    })}
                    </TableBody>
                </Table>
                :
                completedRows.length > 0 &&
                <Table padding="none" aria-label="simple table">
                    <TableHead >
                        <TableRow>
                            <TableCell align="center" height="50px" width="30%" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>Nikshay ID Entered</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500}}>ID Updated to</TableCell>
                            <TableCell align="center" height="50px" className={classes.tableHeader} style={{ borderRight: "1px solid #DADCE0", minHeight: "50px", fontWeight: 500 }}>Updated on</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {completedRows.map((row, i) => {
                        return (
                            <TableRow key={i}>
                            <TableCell height="50px" align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.lab_sample_id === "id not present" ? "ID Not Present": row.lab_sample_id}</Typography>
                            </TableCell>
                            <TableCell height="50px" align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData}>{row.updated_id}</Typography>
                            </TableCell>
                            <TableCell height="50px" align="center" style={{ borderRight: "1px solid #DADCE0", borderBottom: "1px solid #DADCE0", minHeight: "50px" }}>
                            <Typography className={classes.tableData} style={{color: globalColors.textColor3}}>{row.updated_at}</Typography>

                            </TableCell>
                            
                            </TableRow>
                    )
                    })}
                    </TableBody>
                </Table>

                }
            </Grid>
            
        </>
    )
}
