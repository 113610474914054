import React, { useEffect, useState } from "react";

import PendingTask from "../components/pendingTask";

export default function Test(){
    const output = [1,2,3,4,5,6]
    return (
        <>
            <PendingTask output={output} />
        </>
    )
}