export const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const batchApi = SERVER_URL + "batch/";
export const sheetApi = SERVER_URL + "sheet/";
export const userApi = SERVER_URL + "user/";
export const dashboardApi = SERVER_URL + "dashboard/";
export const interpretationApi = SERVER_URL + "interpretation/"
export const hierarchyApi = SERVER_URL + "hierarchy/"
export const stateApi = SERVER_URL + "state/"
export const districtApi = SERVER_URL + "district/"
export const labApi = SERVER_URL + "lab/"
export const sampleInfoApi = SERVER_URL + "sample/"
export const submitApi = sampleInfoApi + "submit-for-mb/"

export const sampleConditionsMapping = {
    "FL": {
        "rif_and_inh_sensitive": "M.tb detected: Rif and Inh Sensitive",
        "send_for_sl_lpa": "M.tb detected: Send for SL-LPA",
        "invalid_repeat_fl_lpa": "Invalid: Repeat FL-LPA",
        "send_for_culture": "M.tb not detected: Send for Culture",
        "indeterminate_repeat_fl_lpa": "Indeterminate: Repeat FL-LPA",
        "invalid_control_bands": "Invalid Control bands: Repeat FL-LPA"
    },
    "SL": {
        "all_sensitive": "M.tb detected: All Sensitive",
        "flq_sensitive_sli_resistant": "M.tb detected: FLQ sensitive, SLI resistant",
        "flq_resistant_sli_sensitive": "M.tb detected: FLQ resistant, SLI sensitive",
        "flq_resistant_sli_resistant": "M.tb detected: FLQ resistant, SLI resistant",
        "invalid_repeat_sl_lpa": "Invalid: Repeat SL-LPA",
        "indeterminate_repeat_sl_lpa": "Indeterminate: Repeat SL-LPA",
        "send_for_culture": "M.tb not detected: Send for Culture",
        "invalid_control_bands": "Invalid Control bands: Repeat SL-LPA"
    }
}
