// src/routes/index.js
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import BatchValidity from "../pages/BatchValidity";
// import DWT from '../components/dwtScan'
import Dashboard from "../pages/Dashboard";
import Interpretation from "../pages/Interpretation";
import MatchLabIds from "../pages/MatchLabIds";
import React from "react";
import SampleInformationCard from "../pages/SampleInformationCard"
import SampleSummaryTable from "../pages/SampleSummaryTable";
import Scanner from "../pages/ScannerUpload";
import Summary from "../pages/Summary";
import { useSelector } from "react-redux";
import Test from "../pages/Test";


const App = () => {
  const loginUrl = useSelector(
    (state) => state.globalActionsReducer.login_url
  );
  const isAuthenticated = useSelector(
    (state) => state.globalActionsReducer.is_authenticated
  );

  const userType = useSelector(
    (state) => state.globalActionsReducer.user_type
  );

  React.useEffect(() => {
    if(!isAuthenticated && loginUrl != ''){
      window.location.replace(loginUrl)
    } 
  }, [isAuthenticated, loginUrl])

  const [state, setState] = React.useState("d");


  React.useEffect(() => {
    setState("d")
  }, [userType])

  function renderPage () {
    if (state === "u") {
      return <Scanner setState={setState} />
    } else
    if (state === "m") { return <MatchLabIds setState={setState}/> } else
    if (state === "b") { return <BatchValidity setState={setState}/> } else
    if (state === "i") { return <Interpretation setState={setState}/> } else
    if (state === "t") { return <SampleSummaryTable setState={setState}/> } else
    if (state === "c") { return <SampleInformationCard setState={setState}/> } else
    if (state === "s") { return <Summary state={state} setState={setState}/> } else 
    if (state === "f") { return <Summary state={state} setState={setState}/> } else {
    // var pageRoutes = Array.from(Array(30).keys()).map((i) => (
    //     <Route exact path={s.concat(i + 1)} key={i + 1}>
    //     <Dashboard userId = {i + 1} demo={false} setState={setState} />
    //     </Route>
    //     ));
    // var pageRoutes.push(
    //   <Route exact path={s.concat("demo")} key={"demo"}>
    //     <Dashboard userId ={15} demo={true} setState={setState} />
    //   </Route>
    // )
        // pageRoutes.push(
        //   <Route exact path='/testing' key='testing'>
        //     <Summary state={state} setState={setState} />
        //   </Route>
        // )
      return (
        <>
        <Route exact path="/">
          <Dashboard userId={0} setState={setState} demo={false}/>
        </Route>
        <Route exact path="/sample">
          <SampleSummaryTable />
        </Route>
        <Route exact path="/task">
          <Test />
        </Route>
        </>
      )
      ;
    }
    
  }
  
 return (
    <Router>
      <Switch>
      {renderPage()}
      </Switch>
    </Router>
  );
}
export default App;
